const photosBaking = [
  {src: "/photos/title.png", width: 260, height: 264, title: "Baking!", subtitle: "Fav stay-at-home pastime"},
  {src: "/photos/Baking/PXL_20210109_114542080.PORTRAIT.jpg", width: 3024, height: 4032},
  {src: "/photos/Baking/IMG-20210109-WA0015.jpg", width: 1600, height: 1074},
  {src: "/photos/Baking/IMG_0199.jpg", width: 4032, height: 3024},
  {src: "/photos/Baking/IMG_0194.jpg", width: 4032, height: 3024},
  {src: "/photos/Baking/IMG_4229.HEIC", width: 4032, height: 3024},
];

const photosCooking = [
  {src: "/photos/title.png", width: 260, height: 264, title: "Cooking", subtitle: ""},
  {src: "/photos/cooking/00000IMG_00000_BURST20200415125450652_COVER.jpg", width: 3024, height: 4032},
  {src: "/photos/cooking/IMG_9572.jpg", width: 3024, height: 4032},
  {src: "/photos/cooking/Image from iOS (23).jpg", width: 4032, height: 3024},
  {src: "/photos/cooking/Image from iOS (26).jpg", width: 4032, height: 3024},
  {src: "/photos/cooking/PXL_20201025_003600804.jpg", width: 3024, height: 4032},
];

const photosCrocheting = [
  {src: "/photos/title.png", width: 260, height: 264, title: 'Crocheting'},
  {src: "/photos/Crochet/IMG_20210309_095442.jpg", width: 3456, height: 3456},
  {src: "/photos/Crochet/Image from iOS (17).jpg", width: 3024, height: 4032},
  {src: "/photos/Crochet/IMG_2525.HEIC", width: 3024, height: 4032},
  {src: "/photos/Crochet/Image from iOS (14).jpg", width: 4032, height: 3024},
  {src: "/photos/Crochet/Image from iOS (19).jpg", width: 3024, height: 4032},
  {src: "/photos/Crochet/Image from iOS (13).jpg", width: 3024, height: 4032},
];

const photosEating = [
  {src: "/photos/title.png", width: 260, height: 264, title: 'Eating'},
  {src: "/photos/Eating/Image from iOS (8).jpg", width: 3024, height: 4032},
  {src: "/photos/Eating/Image from iOS (10).jpg", width: 3024, height: 4032},
  {src: "/photos/Eating/Image from iOS (11).jpg", width: 4032, height: 3024},
  {src: "/photos/Eating/Image from iOS (9).jpg", width: 3024, height: 4032},
  {src: "/photos/Eating/IMG_20210319_201234.jpg", width: 4608, height: 3456},
  {src: "/photos/Eating/IMG_6638.jpg", width: 5472, height: 3648},
  {src: "/photos/Eating/IMG_20210410_202257.jpg", width: 3456, height: 3456},
  {src: "/photos/Eating/IMG_3627.jpg", width: 3024, height: 4032},
  {src: "/photos/Eating/Image from iOS (21).jpg", width: 3024, height: 4032},
  {src: "/photos/Eating/image (1).png", width: 3024, height: 4032},
  {src: "/photos/Eating/Image from iOS (15).jpg", width: 1600, height: 777},
  {src: "/photos/Eating/IMG-20210328-WA0003.jpg", width: 1200, height: 1600},
  {src: "/photos/Eating/IMG20201010134154.jpg", width: 3456, height: 3456},
];

const photosEventChristmas = [
  {src: "/photos/title.png", width: 260, height: 264, title: 'Christmas'},
  {src: "/photos/Event - Christmas/IMG_7452.HEIC", width: 4032, height: 3024},
  {src: "/photos/Event - Christmas/PHOTO-2020-12-24-13-05-10.jpg", width: 1200, height: 1600},
  {src: "/photos/Event - Christmas/IMG_20201224_102004.jpg", width: 4608, height: 3456},
  {src: "/photos/Event - Christmas/PHOTO-2020-12-24-16-42-52.jpg", width: 1600, height: 1200},
  {src: "/photos/Event - Christmas/IMG_7443.JPG", width: 4032, height: 2448},
  {src: "/photos/Event - Christmas/PHOTO-2020-12-24-21-24-28.jpg", width: 900, height: 1600},
  {src: "/photos/Event - Christmas/IMG_7387.HEIC", width: 4032, height: 3024},
  {src: "/photos/Event - Christmas/IMG_7333.HEIC", width: 4032, height: 3024},
];

const photosEventCny = [
  {src: "/photos/title.png", width: 260, height: 264, title: "CNY + Bye Bye Kat"},
  {src: "/photos/Event - CNY and Kat/fba95c68-6933-4209-860d-0578fbbb7637.jpg", width: 1600, height: 900},
  {src: "/photos/Event - CNY and Kat/fa7bdc4e-0d4b-4e09-be20-a7b1ad8c0a28.jpg", width: 1600, height: 900},
  {src: "/photos/Event - CNY and Kat/368c8d36-1555-4c69-85ab-bcc860b49986.jpg", width: 1600, height: 1200},
  {src: "/photos/Event - CNY and Kat/2b590cda-740b-474d-bb0c-5acc3b4f4d47.jpg", width: 1600, height: 900},
  {src: "/photos/Event - CNY and Kat/c2049f9d-2ff6-4677-aab9-d180472ee6f5.jpg", width: 1600, height: 1238},
];

const photosFamily = [
  {src: "/photos/title.png", width: 260, height: 264, title: "Family"},
  {src: "/photos/Kids/71a5c26d-0f27-40f1-86fc-dbc26f64ac90.JPG", width: 720, height: 1280},
  {src: "/photos/Kids/FB_IMG_1635823876588.jpg", width: 1080, height: 810},
  {src: "/photos/Kids/Image from iOS (20).jpg", width: 777, height: 1600},
  {src: "/photos/Kids/IMG_9935.jpg", width: 4032, height: 3024},
  {src: "/photos/Kids/PHOTO-2021-05-06-13-04-19.jpg", width: 1024, height: 1024},
  {src: "/photos/Kids/Image from iOS (7).jpg", width: 3024, height: 4032},
];

const photosMeetings = [
  {src: "/photos/title.png", width: 260, height: 264, title: "Meetings"},
  {src: "/photos/Meetings/image (4).png", width: 1127, height: 883},
  {src: "/photos/Meetings/IMG_2651.HEIC", width: 4032, height: 3024},
  {src: "/photos/Meetings/image (3).png", width: 1211, height: 1175},
  {src: "/photos/Meetings/Screen Shot 2021-07-01 at 16.06.04.png", width: 893, height: 556},
  {src: "/photos/Meetings/IMG_5031.HEIC", width: 4032, height: 3024},
  {src: "/photos/Meetings/Image from iOS (25).jpg", width: 4032, height: 3024},
  {src: "/photos/Meetings/7c11a06a-2d04-49a0-9fc0-cee621a260d3.jpg", width: 1600, height: 678},
];

const photosMeetups = [
  {src: "/photos/title.png", width: 260, height: 264, title: "Meetups"},
  {src: "/photos/Meetups/image (2).png", width: 3024, height: 4032},
  {src: "/photos/Meetups/PHOTO-2021-03-02-21-33-00.jpg", width: 1600, height: 1200},
  {src: "/photos/Meetups/IMG_20201012_120726.jpg", width: 4608, height: 3456},
  {src: "/photos/Meetups/a83105fa-0c8e-42cc-9535-3ae7b3ef2c3c.jpg", width: 1600, height: 1200},
];

const photosNatureAndOutdoors = [
  {src: "/photos/title.png", width: 260, height: 264, title: "Nature and outdoors"},
  {src: "/photos/Nature and outdoors/IMG_7873.jpg", width: 3024, height: 4032},
  {src: "/photos/Nature and outdoors/Screenshot 2020-08-11 at 12.40.08 PM.png", width: 566, height: 574},
  {src: "/photos/Nature and outdoors/Image from iOS (18).jpg", width: 3024, height: 4032},
  {src: "/photos/Nature and outdoors/Image from iOS (22).jpg", width: 3024, height: 4032},
  {src: "/photos/Nature and outdoors/IMG20201206112418.jpg", width: 3456, height: 4608},
  {src: "/photos/Nature and outdoors/IMG_20210607_153042.jpg", width: 4000, height: 2250},
  {src: "/photos/Nature and outdoors/IMG_1673.jpg", width: 3024, height: 4032},
  {src: "/photos/Nature and outdoors/IMG_2972.JPG", width: 1980, height: 3520},
]

const photosNewgear = [
  {src: "/photos/title.png", width: 260, height: 264, title: "New gear"},
  {src: "/photos/New gear/IMG_2713.jpg", width: 4032, height: 3024},
  {src: "/photos/New gear/5gjbjr.jpeg", width: 535, height: 467},
  {src: "/photos/New gear/Image from iOS.jpg", width: 3024, height: 4032},
  {src: "/photos/New gear/IMG_5618.HEIC", width: 4032, height: 3024},
  {src: "/photos/New gear/Image from iOS (4).jpg", width: 4032, height: 3024},
  {src: "/photos/New gear/IMG_20210210_153102.jpg", width: 1744, height: 3648},
  // { src: "/photos/New gear/IMG_5596.HEIC", width: 4032, height: 3024 },
  {src: "/photos/New gear/Image from iOS (6).jpg", width: 3024, height: 4032},
  {src: "/photos/New gear/IMG_20200704_113750.jpg", width: 3648, height: 1744},
];

const photosNewOffice = [
  {src: "/photos/title.png", width: 260, height: 264, title: "New office"},
  {src: "/photos/New office/workspace-andrew.jpg", width: 3648, height: 2736},
  {src: "/photos/New office/workspace-ben.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-brecht.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-daphne.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-daphne2.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-daryl.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-easter.jpg", width: 4608, height: 2112},
  {src: "/photos/New office/workspace-genie.jpg", width: 4000, height: 2250},
  {src: "/photos/New office/workspace-genie2.jpg", width: 4000, height: 2250},
  {src: "/photos/New office/workspace-hm.jpg", width: 3024, height: 4032},
  {src: "/photos/New office/workspace-hooiling.jpg", width: 3685, height: 2931},
  {src: "/photos/New office/workspace-huy.jpg", width: 3685, height: 2931},
  {src: "/photos/New office/workspace-jackson.jpg", width: 3024, height: 3024},
  {src: "/photos/New office/workspace-kianan.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-kianan2.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-kien.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-kyle.jpg", width: 5120, height: 3840},
  {src: "/photos/New office/workspace-kyle2.jpg", width: 3968, height: 2976},
  {src: "/photos/New office/workspace-paul.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-peh.jpg", width: 3024, height: 4032},
  {src: "/photos/New office/workspace-ping.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-rohit.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-ry.png", width: 3648, height: 1744},
  {src: "/photos/New office/workspace-ryan.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-severine.jpg", width: 3024, height: 3024},
  {src: "/photos/New office/workspace-sugan.JPG", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-tash.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-weiseng.jpg", width: 4032, height: 3024},
  {src: "/photos/New office/workspace-yani.jpeg", width: 1600, height: 1200},
  {src: "/photos/New office/workspace-zavien.jpg", width: 3000, height: 4000},
];

const photosOldOffice = [
  {src: "/photos/title.png", width: 260, height: 264, title: "Old office"},
  {src: "/photos/Old office/a1678fd1-3250-42e2-bd2f-1067b8c7ced0.jpg", width: 1600, height: 1200},
  {src: "/photos/Old office/58f84a9c-2452-4835-a3b0-28f39777af70.jpg", width: 1600, height: 1200},
  {src: "/photos/Old office/e256fae0-7e74-4a43-8f1d-8c0332839860.jpg", width: 1600, height: 1200},
  {src: "/photos/Old office/3607296f-1dcd-457d-bc08-8d3b7e100fe3.jpg", width: 1600, height: 1200},
  {src: "/photos/Old office/99506bfb-8605-4e00-afb0-a9e704870680.jpg", width: 1600, height: 1200},
  {src: "/photos/Old office/407194c8-d8f1-4d0f-8c4d-9ca698995086.jpg", width: 1600, height: 1200},
  {src: "/photos/Old office/e6c152f4-1150-432b-9829-166f95ee42ac.jpg", width: 1200, height: 1600},
  {src: "/photos/Old office/7367b2d2-b5df-42b3-b78f-90a5c763e1be.jpg", width: 1600, height: 1200},
  {src: "/photos/Old office/92d151c9-181b-4ed5-950f-84353a789026.jpg", width: 1200, height: 1600},
  {src: "/photos/Old office/8fcc5de6-a064-49f9-9cc8-d9a6752ebc17.jpg", width: 1200, height: 1600},
  {src: "/photos/Old office/e328863e-9a21-40fb-845a-6cdf3dbe93bf.jpg", width: 1600, height: 1200},
  {src: "/photos/Old office/80157065-4149-45d4-a9bf-0afe5267e47b.jpg", width: 1200, height: 1600},
];

const photosOnsite = [
  {src: "/photos/title.png", width: 260, height: 264, title: "Onsite"},
  {src: "/photos/Onsite/IMG_3368.HEIC", width: 4032, height: 3024},
  {src: "/photos/Onsite/97a4df37-c982-4b19-9a4e-8ff6e2919fd8.jpg", width: 1600, height: 1200},
  {src: "/photos/Onsite/a003f2ca-7b62-4d7e-abb6-711842e36a1c.jpg", width: 1600, height: 1200},
  {src: "/photos/Onsite/IMG_0775.HEIC", width: 4032, height: 3024},
  {src: "/photos/Onsite/IMG_8446.HEIC", width: 4032, height: 3024},
  {src: "/photos/Onsite/IMG_1662.jpg", width: 3024, height: 4032},
  {src: "/photos/Onsite/f04f4e78-89f4-4b87-965d-fdc07e7461aa.jpg", width: 1600, height: 1200},
  {src: "/photos/Onsite/6978c00e-5b83-4738-be2d-e6a44641ab80.jpg", width: 1600, height: 1139},
  {src: "/photos/Onsite/b104c96f-a71e-4f52-b5a6-97d1d5055858.jpg", width: 1600, height: 1200},
  {src: "/photos/Onsite/679a593c-2d08-4e04-88a6-acfd95cc686c.jpg", width: 1600, height: 1200},
];

const photosPetsAndHobbies = [
  {src: "/photos/title.png", width: 260, height: 264, title: "Pets and hobbies"},
  {src: "/photos/Pets and hobbies/IMG_4748.JPG", width: 1980, height: 3520},
  {src: "/photos/Pets and hobbies/IMG_20210125_222851.jpg", width: 3456, height: 3456},
  {src: "/photos/Pets and hobbies/IMG_20210427_140406.jpg", width: 4608, height: 3456},
  {src: "/photos/Pets and hobbies/Image from iOS (16).jpg", width: 4032, height: 3024},
  {src: "/photos/Pets and hobbies/Image from iOS (24).jpg", width: 4032, height: 3024},
  // { src: "/photos/Pets and hobbies/IMG_4839.jpg", width: 3024, height: 4032 },
  // { src: "/photos/Pets and hobbies/IMG_4450.HEIC", width: 4032, height: 3024 },
  {src: "/photos/Pets and hobbies/IMG_5667.HEIC", width: 3024, height: 4032},
  {src: "/photos/Pets and hobbies/IMG_5289.HEIC", width: 3024, height: 4032},
];

const photosSideProjectsAndMilestones = [
  {src: "/photos/title.png", width: 260, height: 264, title: "Side projects and milestones"},
  {src: "/photos/Side projects and milestones/image (5).png", width: 714, height: 366},
  {src: "/photos/Side projects and milestones/e624273a-08a9-4b80-aa4e-f7771796f5e1.jpg", width: 1440, height: 825},
  {src: "/photos/Side projects and milestones/a624d42c-39b5-45a2-b096-e4ffac5e2ede.jpg", width: 1081, height: 1080},
  {src: "/photos/Side projects and milestones/Image from iOS.png", width: 1125, height: 2436},
  {src: "/photos/Side projects and milestones/Screenshot 2021-01-19 at 1.27.19 PM.png", width: 3584, height: 2274},
  {src: "/photos/Side projects and milestones/20200818_150750.jpg", width: 3024, height: 4032},
  {src: "/photos/Side projects and milestones/Screenshot 2020-12-15 at 12.31.43.png", width: 1760, height: 1344},
]

const photosTBCrochet = [
  {src: "/photos/title.png", width: 260, height: 264, title: "TB - Crochet"},
  {src: "/photos/TB - Crochet/IMG_1355.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Crochet/IMG_1380.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Crochet/IMG_1288.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Crochet/IMG_1266.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Crochet/IMG_1282.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Crochet/IMG_1327.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Crochet/IMG_1370.JPG", width: 4032, height: 3024},
  {src: "/photos/TB - Crochet/IMG_1245.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Crochet/IMG_1272.HEIC", width: 4032, height: 3024},
]

const photosTBOnline = [
  {src: "/photos/title.png", width: 260, height: 264, title: "TB - Online"},
  {src: "/photos/TB - Online/Screenshot 2021-05-18 at 3.51.51 PM.png", width: 2658, height: 1528},
  {src: "/photos/TB - Online/5096c01d-c9bf-4563-bf50-e90efd581b1e.jpg", width: 1200, height: 1600},
  {src: "/photos/TB - Online/IMG_1508.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Online/Screenshot 2020-04-21 at 18.44.28.png", width: 3248, height: 1938},
  {src: "/photos/TB - Online/IMG_1514.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Online/IMG_1515.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Online/d249ecdb-8ea8-48e5-918b-18beee134c0e.jpg", width: 1600, height: 1344},
  {src: "/photos/TB - Online/IMG_1520.HEIC", width: 4032, height: 3024},
  {src: "/photos/TB - Online/IMG_4016.HEIC", width: 4032, height: 3024},
];

export const photos = [
  // Not so fun
  ...photosOldOffice,
  ...photosOnsite,
  ...photosNewOffice,
  ...photosNewgear,
  ...photosMeetings,
  ...photosBaking,
  ...photosCooking,
  ...photosCrocheting,
  ...photosEating,
  ...photosEventChristmas,
  ...photosEventCny,
  ...photosFamily,
  ...photosTBCrochet,
  ...photosTBOnline,
  ...photosMeetups,
  ...photosNatureAndOutdoors,
  ...photosPetsAndHobbies,
  ...photosSideProjectsAndMilestones,
];
